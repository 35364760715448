<script>
export default {
  template: '<template></template>',
}
</script>

<style lang="scss">
@import '../../ArticleContainer/ArticleContainer';
@import '../../ArticleItem/ArticleItem';
@import '../../MakeModelList/MakeModelList';
@import '../../HeroBanner/HeroBanner';
@import '../../TrustBand/TrustBand';
@import '../../ArticleInspirationBanner/ArticleInspirationBanner';
@import '../../AboutUsBanner/AboutUsBanner';
</style>

<template>
  <div class="license-plate-lookup">
    <header class="license-plate-lookup__title">
      <h2 class="license-plate-lookup__title-text">Tjek nummerplade</h2>
    </header>
    <div class="license-plate-lookup__border">
      <div class="license-plate-lookup__content">
        <div class="license-plate-lookup__description">
          Indtast din nummerplade, og få nyttige oplysninger om din bil
        </div>
        <div class="license-plate-lookup__interactives">
          <div class="license-plate-lookup__input-container">
            <p class="error" v-if="licensePlateError">
              <span>{{ licensePlateError }}</span>
              <span class="error__attention"></span>
            </p>
            <label class="sr-only" for="license-plate-lookup__input"
              >Skriv din nummerplade her</label
            >
            <div
              class="input input--search license-plate-lookup__input--search"
            >
              <input
                class="input--search"
                title="Skriv din nummerplade her"
                id="license-plate-lookup__input"
                type="text"
                placeholder="Skriv din nummerplade her"
                v-model="licensePlate"
                @keyup.enter="search()"
                ref="licensePlateInput"
              />
              <ClearInput
                :event-bus="eventBus"
                :value="licensePlate"
                :search-icon="true"
              />
            </div>
          </div>

          <button
            class="button button--highlight"
            :disabled="inProgress"
            @click.prevent="search()"
          >
            hent info
            <ButtonSpinner v-if="inProgress" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mitt from 'mitt'

import ClearInput from '@shared/ClearInput/ClearInput.vue'
import ButtonSpinner from '@components/Shared/ButtonSpinner/ButtonSpinner.vue'

export default {
  components: {
    ClearInput,
    ButtonSpinner,
  },
  data() {
    return {
      licensePlate: '',
      licensePlateError: '',
      eventBus: mitt(),
      inProgress: false,
    }
  },
  methods: {
    search() {
      this.inProgress = true
      if (!this.validateForm()) {
        this.inProgress = false
        return
      }
      window.location.href = '/nummerpladeopslag/' + this.licensePlate
    },
    validateForm() {
      if (this.licensePlate == '') {
        this.licensePlateError = 'Indtast nummerpladen på køretøjet'
        return false
      } else if (this.licensePlate.length < 2 || this.licensePlate.length > 7) {
        this.licensePlateError =
          'En dansk nummerplade skal være 2-7 karakterer (inkl. evt. mellemrum)'
        return false
      }
      this.licensePlateError = ''
      return true
    },
    clearSearchString() {
      this.inProgress = false
      this.licensePlate = ''
      this.$refs.licensePlateInput.focus()
    },
  },
  created() {
    if (this.eventBus === null || this.eventBus === undefined) return
    this.eventBus.on('clear-input-string', this.clearSearchString)
  },
  watch: {
    licensePlate() {
      if (this.licensePlateError != '') {
        this.licensePlateError = ''
      }
    },
  },
}
</script>

<style lang="scss">
@import 'LicensePlateLookup.scss';
</style>

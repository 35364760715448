<template>
  <div
    class="search overlay--dropdown"
    :class="{
      'search--full': isAdvancedSearchOpen,
      'overflow-hidden-sm': isChildOpen,
    }"
    role="search"
  >
    <div v-if="isAdvancedSearchOpen" class="overlay__top-container">
      <div class="overlay__placeholder"></div>
      <div class="overlay__top">Udvidet søgning</div>
      <button
        @click.prevent="closeAdvancedSearch"
        class="overlay__top"
        type="button"
      >
        <div aria-label="Luk" class="icon--plus icon--rotate"></div>
      </button>
    </div>

    <OverlayContainer class="search__components" :event-bus="eventBus">
      <component
        v-for="(component, index) in ui.components"
        v-bind="component.props"
        :key="index"
        :is="component.type"
        :id="component.id"
        :event-bus="eventBus"
        :product-type-id="searchRequest[0]"
        :is-open="isAdvancedSearchOpen"
        :ads-count="adsCount"
        :search-request="searchRequest"
        :product-type-name-singular="productTypeNameSingular"
        :product-type-name-plural="productTypeNamePlural"
        :search-types="component.searchTypes"
      ></component>
      <div
        class="search__bottom-container"
        v-if="ui.components.findIndex(x => x.type === 'AdvancedSearch') === -1"
      >
        <SearchCounter
          :ads-count="adsCount"
          :product-type-name-singular="productTypeNameSingular"
          :product-type-name-plural="productTypeNamePlural"
        />
        <div class="search__button-container">
          <div class="search__button">
            <button
              @click="performSearch"
              type="button"
              class="button button--highlight advanced-search__button"
            >
              Søg
            </button>
          </div>
        </div>
      </div>
    </OverlayContainer>
  </div>
</template>

<script>
import { app } from '@/main'

import { toggleDocumentOverflow } from '@/functions'

import SearchMixin from '@components/Search/search-mixin.vue'
import SearchCounter from '@search/SearchCounter/SearchCounter.vue'

export default {
  mixins: [SearchMixin],
  components: {
    SearchCounter,
  },
  data() {
    return {
      isAdvancedSearchOpen: false,
      countAds: false,
    }
  },
  created() {
    app.mitt.on('close-advanced-search', this.closeAdvancedSearch)

    app.mitt.on('open-advanced-search', this.openAdvancedSearch)
  },
  mounted() {
    this.countAds = true
  },
  methods: {
    closeAdvancedSearch() {
      app.history.pop()
    },
    performSearch() {
      app.mitt.emit('create-search')
    },
    openAdvancedSearch() {
      const owner = this

      app.history.push(() => {
        owner.isAdvancedSearchOpen = false
        toggleDocumentOverflow(false)
      }, 'pop-all-immune')
      this.isAdvancedSearchOpen = true
      toggleDocumentOverflow(true)
    },
  },
}
</script>

<style lang="scss">
@import 'Search.scss';
</style>

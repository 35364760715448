<script>
import { LogArticleItemClicked } from '@/events'

export default {
  template: '<template></template>',
  mounted() {
    const containers = document.getElementsByClassName('js-article-container')

    containers.forEach(container => {
      this.handleArticleItemClicked(container)
    })
  },
  methods: {
    handleArticleItemClicked(container) {
      this.handleLink(container, 'js-article-link', () => {
        LogArticleItemClicked()
      })
    },
    handleLink(container, className, callback, eventName = 'click') {
      const links = container.getElementsByClassName(className)

      links.forEach(link => {
        link.addEventListener(eventName, () => {
          callback()
        })
      })
    },
  },
}
</script>
